<template>
	<div class="appli">
	   <h3 class="title">企业应用中心</h3>
	   <div class="appli_box">
	   	  <div class="appli_box_left">
	   	  	<p>要安全 、 过等保 <br>就找万方安全</p>
	   	  	<span>提供等保测评 、风险评估 、安全整改等安全服务</span>
	   	  	<router-link to="/details">立即访问</router-link>
	   	  </div>
	   	  <ul class="appli_box_right">
	   	  	<li>
	   	  		<div class="appli_tag">
                    <span>众多成功案例</span>
                    <span>收费透明</span>
                </div>
                <h5 class="card_title">等保二级测评</h5>
                <p class="desc">协助定级备案 ，测评机构差 <br>距测评 ，安全加固整改<br>复测评 ，出具测评报告 。</p>
                <p class="price"><i style="font-size:15px;">￥</i>40000 <span style="font-size:15px;color:#333;">/年起</span></p>
                <div class="items_bott"><router-link to="/details">查看详情</router-link><a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">立即咨询</a></div>
	   	  	</li>
	   	  	<li>
                <div class="appli_tag">
                    <span>众多成功案例</span>
                    <span>收费透明</span>
                </div>
                <h5 class="card_title">等保三级测评</h5>
                <p class="desc">协助定级备案 ，测评机构差 <br>距测评 ，安全加固整改<br>复测评 ，出具测评报告 。</p>
                <p class="price"><i style="font-size:15px;">￥</i>100000 <span style="font-size:15px;color:#333;">/年起</span></p>
                <div class="items_bott"><router-link to="/details">查看详情</router-link><a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">立即咨询</a></div>
            </li>
            <li>
                <div class="appli_tag">
                    <span>团队专业</span>
                    <span>快速有效</span>
                    
                </div>
                <h5 class="card_title">等保整改</h5>
                <p class="desc">根据等保测评问题清单  ，配置 <br>个性化整改方案 ，快速有效通<br>过整改</p>
                <p class="price"><i style="font-size:15px;">￥</i>12000 <span style="font-size:15px;color:#333;">/年起</span></p>
                <div class="items_bott"><router-link to="/dengbaozhenggai">查看详情</router-link><a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">立即咨询</a></div>
            </li>
            <li>
                <div class="appli_tag">
                    <span>定制产品</span>
                    <span>安全放心</span>
     
                </div>
                <h5 class="card_title">等保安全产品</h5>
                <p class="desc">为快速过等保  ，   专业研发符合 <br>等保二级 、   三级的整改套餐产<br>品，  快速安全过等保 。</p>
                <p class="price"><i style="font-size:15px;">￥</i>12000 <span style="font-size:15px;color:#333;">/年起</span></p>
                <div class="items_bott"><router-link to="/dengbaohegui">查看详情</router-link><a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">立即咨询</a></div>
            </li>
            <li>
                <div class="appli_tag">
                    <span>经验丰富</span>
                    <span>快速通过</span>
                </div>
                <h5 class="card_title">网络安全自测评</h5>
                <p class="desc">增值电信业务经营许可证<br>ICP/EDI  办理  ，   信息安全风险<br>评估自测评报告协助编写   。</p>
                <p class="price"><i style="font-size:15px;">￥</i>12000 <span style="font-size:15px;color:#333;">/年起</span></p>
                <div class="items_bott"><router-link to="/zice">查看详情</router-link><a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">立即咨询</a></div>
            </li>
            <li>
                <div class="appli_tag">
                    <span>专业服务</span>
                    <span>入网无忧</span>
                </div>
                <h5 class="card_title">入网安评</h5>
                <p class="desc">具有舆论属性安全风险评估 <br>APP安全保障风险评估<br>网站及系统风险评估</p>
                <p class="price"><i style="font-size:15px;">￥</i>12000 <span style="font-size:15px;color:#333;">/年起</span></p>
                <div class="items_bott"><router-link to="/ruwangsafe">查看详情</router-link><a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">立即咨询</a></div>
            </li>
            <li>
                <div class="appli_tag">
                    <span>授权资质</span>
                    <span>快速出报告</span>
                </div>
                <h5 class="card_title">ICP/EDI  第三方测评</h5>
                <p class="desc">深圳  ICP/EDI 办理 <br>增值电信业务经营许可证</p>
                <p class="price"><i style="font-size:15px;">￥</i>12000 <span style="font-size:15px;color:#333;">/年起</span></p>
                <div class="items_bott"><router-link to="/icpedi">查看详情</router-link><a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">立即咨询</a></div>
            </li>
            <li>
                <div class="appli_tag">
                    <span>众多案例</span>
                    <span>快速出报告</span>
                </div>
                <h5 class="card_title">网约车风险评估</h5>
                <p class="desc">互联网新技术新业务风险评估 <br>APP 安全保障能力风险评估<br/>符合性测评三级</p>
                <p class="price"><i style="font-size:15px;">￥</i>12000 <span style="font-size:15px;color:#333;">/年起</span></p>
                <div class="items_bott"><router-link to="/wangyueche">查看详情</router-link><a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">立即咨询</a></div>
            </li>
            <li>
                <div class="appli_tag">
                    <span>经验丰富</span>
                    <span>快速整改</span>
                </div>
                <h5 class="card_title">风险评估整改</h5>
                <p class="desc">专业团队 、配置个性化服务 ， <br>快速、有效 、安全整改；</p>
                <p class="price"><i style="font-size:15px;">￥</i>12000 <span style="font-size:15px;color:#333;">/年起</span></p>
                <div class="items_bott"><router-link to="/">查看详情</router-link><a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">立即咨询</a></div>
            </li>
	   	  </ul>
	   </div>
       <Hots/>
	</div>
</template>
<script type="text/javascript">

import  Hots from "./hotComponent.vue"

export default{
  data(){
        return{}
    },
    components:{
        Hots
    },
    mounted(){

    }
};

</script>

<style type="text/css" scoped>
.appli{
  width:1200px;
  margin:0 auto;
}
.appli .title{
    color: #000;
    font-size: 24px;
    font-weight: normal;
    display: block;
}
.appli_box{
    width: 1200px;
    margin-top:20px;
    height: 600px;
    background-color: #fff;
    /*overflow:hidden;*/
}
.appli_box_left{
	width: 300px;
    height: 600px;
    float: left;
    padding-left: 30px;
    padding-top: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    background:url(../../../assets/images/a03.jpg) center center / cover no-repeat;
}
.appli_box_left p{
  font-size:18px;
  line-height: 28px;
  color:#0e82cb;
  font-weight:700;
}
.appli_box_left span{
	font-size:14px;
	line-height: 24px;
	 color:#0e82cb;
	display: inline-block;
	margin:5px 0;
}
.appli_box_left a:hover{
    background:#fff;
    color:#0e82cb;
}
.appli_box_left a{
	display: block;
    border: 1px solid #0e82cb;
    width: 128px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    color: #0e82cb;
    cursor: pointer;
    margin-top: 32px;
    text-decoration: none;
}
.appli_box_right{
	width: 900px;
    height:600px;
    float: left;
}
.appli_box_right>li{
	float: left;
    width: 300px;
    height: 200px;
    padding-top: 20px;
    position: relative;
    box-sizing: border-box;
    border-bottom: 1px solid #ededed;
    border-right: 1px solid #ededed;
}
.appli_tag{
    margin-left: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 20px;
}
.appli_tag span{
    display: inline-block;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    height: 20px;
    line-height: 16px;
    padding: 1px 6px;
    text-align: center;
    vertical-align: center;
    font-size: 12px;
    color: #999;
    box-sizing: border-box;
    margin-left: 8px;
}
.appli_tag span:first-child{
    margin-left:0;
}
.card_title{
    margin-left: 20px;
    text-align: left;
    font-size: 14px;
    color: #181818;
    letter-spacing: .65px;
    line-height: 30px;
    height: 30px;
    margin-top: 13px;
    margin-bottom: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.desc{
    margin-left: 20px;
    text-align: justify;
    line-height: 18px;
    font-size: 12px;
    white-space: pre-line;
    color: #999;
    letter-spacing: .56px;
    margin-right: 20px;
    height:54px;
}
.price{
    margin-left:20px;
    margin-top:20px;
    font-size:20px;
    text-align:left;
    color:#ff6a00;
}
.appli_box_right>li:hover  .items_bott{
    opacity: 1;
}
.items_bott{
    opacity: 0;
    transition: all .3s;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 55px;
    padding-left: 0;
    background-color: #ff6a00;
    text-align: center;
    line-height: 55px;
    clear: both;
    z-index:12;
    transition:0.2s all;
}
.items_bott a{
  width:50%;
  line-height: 55px;
  text-align:center;
  display: inline-block;
  font-size:16px;
  text-decoration:none;
 background-color: #fff; 
 border: 1px solid #0e82cb; 
 box-sizing: border-box; 
 color: #0e82cb;
}
.items_bott a:last-child{
    background: #0e82cb;
    color:#fff;
}
	
</style>















