<template>
	<div class="appli">
	   <!-- <h3 class="title">企业应用中心</h3> -->
	   <div class="appli_box">
	   	  <div class="appli_box_left">
	   	  	<p>中小企业网络安全保障</p>
            <p>灵活套餐 、   个性安全</p>
	   	  	<span>提供云安全检测 、安全运维 、安全产品、 专家应急响应等安全服务</span>
            <router-link to="/yitihua">爆款推荐</router-link>
	   	  </div>
	   	  <ul class="appli_box_right">
	   	  	<li>
	   	  		<div class="appli_tag">
                    <span>定制套餐</span>
                    <span>个性服务</span>
                </div>
                <h5 class="card_title">云上业务安全无忧保障计划</h5>
                <!-- <p class="desc">CPU与内存配比1：2 最新代企业级产品，性能全面升级</p> -->
                <ul class="yun_list">
                	<li><i class="el-icon-check"></i>漏洞扫描 、 渗透测试 、漏洞</li>
                	<li><i class="el-icon-check"></i>处置方案报告、漏洞修复、</li>
                    <li><i class="el-icon-check"></i>安全专家应急响应 。</li>
                </ul>
                <p class="price">
                	<i style="font-size:15px;">￥</i>1200 <span style="font-size:15px;color:#333;">/年起</span>
                </p>
                 <div class="items_bott"><router-link to="/yunsafe">查看详情</router-link><a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">立即咨询</a></div>
               <!--  <div class="items_bott"><a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">立即购买</a></div> -->
	   	  	</li>
	   	  	<li>
	   	  		<div class="appli_tag">
                    <span>专业产品</span>
                    <span>安全有保障</span>
                </div>
                <h5 class="card_title">云上业务安全产品</h5>
                <!-- <p class="desc">提供稳定计算力，适用于均衡性能场景，推荐企业选用 </p> -->
                <ul class="yun_list">
                	<li><i class="el-icon-check"></i>云防火墙、WEB 应用防火墙、</li>
                	<li><i class="el-icon-check"></i>堡垒机数据库审计、日志审计</li>
                    <li><i class="el-icon-check"></i> SSL 证书 、云主机病毒防护</li>
                </ul>
                <p class="price">
                	<i style="font-size:15px;">￥</i>1000 <span style="font-size:15px;color:#333;">/年起</span>
                </p>
                <div class="items_bott"><router-link to="/dengbao3">查看详情</router-link><a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">立即咨询</a></div>
              <!--   <div class="items_bott"><a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">立即购买</a></div> -->
	   	  	</li>
            <li>
	   	  		<div class="appli_tag">
                    <span>服务全面</span>
                    <span>省心省力</span>
                </div>
                <h5 class="card_title">云安全托管 </h5>
             
                <ul class="yun_list">
                	<li><i class="el-icon-check"></i>云主机   /   云空间 、环境配置   、</li>
                	<li><i class="el-icon-check"></i>应用部署    、   安全维护    、</li>
                    <li><i class="el-icon-check"></i>安全产品配置</li>
                </ul>
                <p class="price">
                	<i style="font-size:15px;">￥</i>5000 <span style="font-size:15px;color:#333;">/年起</span>
                </p>
                <div class="items_bott"><router-link to="/dengbao2">查看详情</router-link><a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">立即咨询</a></div>
               
	   	  	</li>
           <li>
	   	  		<div class="appli_tag">
                    <span>一站式服务</span>
                    <span>省时省心</span>
                </div>
                <h5 class="card_title">等保合规测评服务 </h5>
                <ul class="yun_list">
                	<li><i class="el-icon-check"></i>协助定级备案、专家评审</li>
                	<li><i class="el-icon-check"></i>测评机构差距测评，整改建设 ，</li>
                    <li><i class="el-icon-check"></i>测评机构复测评，出具测评报告 。</li>
                </ul>
                <p class="price">
                	<i style="font-size:15px;">￥</i>10000<span style="font-size:15px;color:#333;">/年起</span>
                </p>
                <div class="items_bott"><router-link to="/details">查看详情</router-link><a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">立即咨询</a></div>
    
	   	  	</li>
	   	  	<li>
	   	  		<div class="appli_tag">
                    <span>专业团队</span>
                     <span>授权资质</span>
                </div>
                <h5 class="card_title">信息安全风险评估服务 </h5>
                <ul class="yun_list">
                	<li><i class="el-icon-check"></i>工信部授权信息安全风险评估</li>
                	<li><i class="el-icon-check"></i>资质,专业人员测评 、出报告</li>
                    <li><i class="el-icon-check"></i>并提供整改加固服务  。</li>
                </ul>
                <p class="price">
                	<i style="font-size:15px;">￥</i>5000<span style="font-size:15px;color:#333;">/年起</span>
                </p>
                <div class="items_bott"><router-link to="/sys">查看详情</router-link><a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">立即咨询</a></div>
            
	   	  	</li>
	   	  	<li>
	   	  		<div class="appli_tag">
                    <span>专业协作</span>
                    <span>服务周到</span>
                </div>
                <h5 class="card_title">合作产品 </h5>
                <!-- <p class="desc">适合图片/音视频等多媒体数据存储 </p> -->
                <ul class="yun_list">
                	<li><i class="el-icon-check"></i>ICP/EDI   增值电信业务经营许</li>
                	<li><i class="el-icon-check"></i>可证咨询办理，</li>
                    <li><i class="el-icon-check"></i>同专业机构合作，全面、快速 、有效服务</li>
                    
                </ul>
                <p class="price">
                	<i style="font-size:15px;">￥</i>30000 <span style="font-size:15px;color:#333;">/年起</span>
                </p>
                <div class="items_bott"><router-link to="/icpedihandle">查看详情</router-link><a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">立即咨询</a></div>
          
	   	  	</li>
        
	   	  </ul>
	   </div>
	   
	</div>
</template>
<script type="text/javascript">

export default{
	name:'hots'
};

</script>

<style type="text/css" scoped>
.appli{
  width:1200px;
  margin:0 auto;
}
.appli .title{
    color: #000;
    font-size: 24px;
    font-weight: normal;
    display: block;
}
.appli_box{
    width: 1200px;
    margin-top:20px;
    height:480px;
    background-color: #fff;
    overflow:hidden;
}
.appli_box_left{
	width: 300px;
    height:480px;
    float: left;
    padding-left: 30px;
    padding-top: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    background:url(../../../assets/images/a04.jpg) center center / cover no-repeat;
    background-size:100% 100%;
}
.appli_box_left p{
  font-size:18px;
  line-height: 28px;
  color:#fff;
  font-weight:700;
}
.appli_box_left span{
	font-size:14px;
	line-height: 24px;
	color:#fff;
	display: inline-block;
	margin:5px 0;
}
.appli_box_left a{
	display: block;
    border: 1px solid #fff;
    width: 128px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    margin-top: 32px;
    text-decoration: none;
}
.appli_box_left a:hover{
	background:#fff;
	color:#0e82cb;
}
.appli_box_right{
	width: 900px;
    height:525px;
    float: left;
}
.appli_box_right>li{
	float: left;
    width: 300px;
    height: 239px;
    padding-top: 20px;
    position: relative;
    box-sizing: border-box;
    border-bottom: 1px solid #ededed;
    border-right: 1px solid #ededed;
}
.appli_tag{
    margin-left: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 20px;
}
.appli_tag span{
    display: inline-block;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    height: 20px;
    line-height: 16px;
    padding: 1px 6px;
    text-align: center;
    vertical-align: center;
    font-size: 12px;
    color: #999;
    box-sizing: border-box;
    margin-left: 8px;
}
.appli_tag span:first-child{
    margin-left:0;
}
.card_title{
    margin-left: 20px;
    text-align: left;
    font-size: 14px;
    color: #181818;
    letter-spacing: .65px;
    line-height: 30px;
    height: 30px;
    margin-top: 13px;
    margin-bottom: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.desc{
    margin-left: 20px;
    text-align: justify;
    line-height: 18px;
    font-size: 12px;
    white-space: pre-line;
    color: #999;
    height:36px;
    letter-spacing: .56px;
    margin-right: 20px;
}
.price{
    margin-left:20px;
    margin-top:20px;
    font-size:20px;
    text-align:left;
    color:#ff6a00;
}
.appli_box_right>li:hover  .items_bott{
    opacity: 1;
}
.items_bott{
    opacity: 0;
    transition: all .3s;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 55px;
    padding-left: 0;
    background-color: #ff6a00;
    text-align: center;
    line-height: 55px;
    clear: both;
    z-index:12;
    transition:0.2s all;
}
.items_bott a{
  width:50%;
  line-height: 55px;
  text-align:center;
  display: inline-block;
  font-size:16px;
  text-decoration:none;
 background-color: #fff; 
 border: 1px solid #0e82cb; 
 box-sizing: border-box; 
 color: #0e82cb;
}
.items_bott a:last-child{
    background: #0e82cb;
    color:#fff;
}
.yun_list{
	padding-left:20px;
	color:#181818;
	line-height: 20px;
	font-size:14px;
	height:65px;
	margin-top:15px;
}
.yun_list i{
	color:#ff6a00;
}
	
</style>














